import { combineReducers } from "redux";
import { createReducer } from "@reduxjs/toolkit";
import authActions from "./actions";

const basicConnectionData = (state, { payload }) => ({
  ...state,
  wsToken: payload.ws_token,
  wsHost: payload.ws_host,
  channel: payload.channel,
  rtcConfiguration: payload.rtc_configuration,
  isRequestSuccess: true,
  expertNickname: payload.expertNickname,
  pin: payload.pin,
  webclientPeerId: payload.constructedWebclientPeerID,
});

const initialConnectionDataState = {
  wsToken: "",
  wsHost: "",
  channel: "",
  rtcConfiguration: {},
  isRequestSuccess: false,
  expertNickname: "",
  pin: null,
  webclientPeerId: "",
};

const connectionData = createReducer(initialConnectionDataState, {
  [authActions.loginSuccess]: basicConnectionData,
  [authActions.logoutSuccess]: () => initialConnectionDataState,
});

const error = createReducer(null, {
  [authActions.loginError]: (_, { payload }) => payload,
  [authActions.logoutError]: (_, { payload }) => payload,

  [authActions.loginRequest]: () => null,
  [authActions.logoutRequest]: () => null,
});

const loading = createReducer(false, {
  [authActions.loginRequest]: () => true,
  [authActions.loginSuccess]: () => false,
  [authActions.loginError]: () => false,

  [authActions.logoutRequest]: () => true,
  [authActions.logoutSuccess]: () => false,
  [authActions.logoutError]: () => false,
});

export default combineReducers({
  connectionData,
  error,
  loading,
});
