import { createTheme } from "@material-ui/core/styles";
import { css } from "styled-components";

export const FONT_SIZE = 16;
export const LINE_SCALE = 1.5;
export const LINE_SIZE = FONT_SIZE * LINE_SCALE;

//TODO: change file for re-usage theme depending on client requirements

export const muiTheme = createTheme({
  palette: {
    type: "dark",
    background: {
      default: "#323649",
    },
    primary: {
      main: "#323649",
      light: "rgb(113,118,139)",
      dark: "rgb(0,23,51)",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
      contrastText: "#505470",
    },
  },
  typography: {
    fontSize: 15,
  },
});

const THEME = {
  // Product
  colorProductPrimary: "#323649",
  colorProductLight: "rgb(113,118,139)",
  colorProductSecondary: "white",
  colorProductDark: "rgb(0,23,51)",
  // TRANSITIONS
  // ---
  transitionDefault: "0.25s ease-in-out",
  // FONT
  // ---
  // fontFamily: '',
  fontWeightLight: "100",
  fontWeightNormal: "normal",
  fontWeightBold: "bold",
  fontColorPrimary: "#505470",
  // Z-INDEX
  // ---
  zBackground: -1,
  zNavbar: 700,
  zModal: 800,
  zOverlay: 900,
  zTooltip: 1000,
  // NAVBAR
  // ---
  navbarHeight: LINE_SIZE * 3,
};

export const dashboardShadow = css`
  box-shadow: inset 7px 47px 17px -50px rgba(0, 0, 0, 0.75);
`;

export default THEME;
