import * as React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import styled, { css } from "styled-components";

const useStyles = makeStyles(() => ({
  customHoverFocus: {
    "&.MuiIconButton-root": {
      borderRadius: 0,
      margin: "0px",
    },
  },
}));

const getIconSize = (props) => {
  if (props.iconSize === "medium") {
    return "1em";
  }
  if (props.iconSize === "large") {
    return "1.5em";
  }
};

const Icon = styled.img`
  max-height: ${getIconSize};
  max-width: ${getIconSize};
`;

const StyledIconButton = styled(IconButton)`
  margin-top: auto;
  margin-bottom: auto;
  width: 2.25em;
  height: 2.25em;
  ${(props) =>
    props.selected &&
    // TODO: change to use styles
    css`
      background: #80829c !important;
    `}
`;

const ButtonRound = ({
  iconSrc,
  selected = false,
  iconSize = "medium",
  children,
  className,
  tooltipTitle,
  disabled,
  onClick,
  ...rest
}) => {
  const classes = useStyles();
  const adjustedButtonProps = {
    disabled: disabled,
    component: disabled ? "div" : undefined,
    onClick: disabled ? undefined : onClick,
  };

  return (
    <Tooltip title={tooltipTitle}>
      <StyledIconButton
        className={`${classes.customHoverFocus || ""} ${className || ""}`}
        selected={selected}
        {...rest}
        {...adjustedButtonProps}
      >
        <Icon src={iconSrc} iconSize={iconSize} />
        {children}
      </StyledIconButton>
    </Tooltip>
  );
};

ButtonRound.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  className: PropTypes.string,
  iconSize: PropTypes.string,
  tooltipTitle: PropTypes.string,
};

export default ButtonRound;
