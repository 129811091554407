import * as React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

import { StreamingContext } from "../../store/context/StreamingContext";
import ToggleButtonRound from "../elements/ToggleButtonRound";

import logoNavIcon from "../../assets/icons/logo-square-blue.png";
import microphoneOnIcon from "../../assets/icons/microphone-on.png";
import microphoneOffIcon from "../../assets/icons/microphone-off.png";
// import CameraIcon from "../../assets/icons/camera.png";
// import noCameraIcon from "../../assets/icons/no-camera.png";
import hangupIcon from "../../assets/icons/hangup.png";

const ContentWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  padding: 5px;

  background: white;
`;

const MainWrapper = styled.div`
  align-self: flex-start;
  width: 100%;
`;

const LogoImg = styled.img``;

const NavbarIconImg = styled.img`
  max-height: 1.5em;
  max-width: 1.5em;
`;

const NavbarIconButton = styled(IconButton)`
  margin-top: auto;
  margin-bottom: auto;
`;

const NavbarIconButtonToggle = styled(ToggleButtonRound)`
  margin-bottom: auto !important;
  margin-top: auto !important;
`;

const FirstNavbarIconButton = styled(NavbarIconButtonToggle)`
  margin-left: auto !important;

  &.Mui-disabled {
    opacity: 0.5;
    cursor: pointer;
    pointer-events: auto;
  }
`;

const Navbar = () => {
  const streamContext = useContext(StreamingContext);
  const { isVideoAllowed, isAudioAllowed } = streamContext.state;

  const onClickMediaAllowedCheckbox = (checked, name) => {
    if (name === "isVideoAllowed") {
      streamContext.setIsVideoAllowed(checked);
    }
    if (name === "isAudioAllowed") {
      streamContext.setIsAudioAllowed(checked);
    }
  };

  const endCall = React.useCallback(() => {
    streamContext.setIsClientHangup(true);
  }, []);

  return (
    <MainWrapper>
      <nav role="navigation">
        <ContentWrapper>
          <LogoImg src={logoNavIcon} alt="VPS logo" height={72} />
          <FirstNavbarIconButton
            checked={isAudioAllowed}
            iconSrc={microphoneOffIcon}
            switchIconSrc={microphoneOnIcon}
            onToggle={(checked) =>
              onClickMediaAllowedCheckbox(checked, "isAudioAllowed")
            }
            iconSize="large"
            tooltipTitle={`microphone is ${isAudioAllowed ? "on" : "off"}`}
            disabled
          />
          {/*<NavbarIconButtonToggle*/}
          {/*  checked={isVideoAllowed}*/}
          {/*  iconSrc={noCameraIcon}*/}
          {/*  switchIconSrc={CameraIcon}*/}
          {/*  onToggle={(checked) =>*/}
          {/*    onClickMediaAllowedCheckbox(checked, "isVideoAllowed")*/}
          {/*  }*/}
          {/*  iconSize="large"*/}
          {/*  tooltipTitle={`camera is ${isVideoAllowed ? "on" : "off"}`}*/}
          {/*/>*/}
          <Tooltip title="end call">
            <NavbarIconButton onClick={endCall}>
              <NavbarIconImg src={hangupIcon} />
            </NavbarIconButton>
          </Tooltip>
        </ContentWrapper>
      </nav>
    </MainWrapper>
  );
};

export default Navbar;
