import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import "webrtc-adapter";

const Video = styled.video`
  height: 100%;
  width: 100%;
  //object-fit: cover;
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;
const WebrtcVideo = ({ stream, videoRef, audio }) => {
  const videoElementRef = videoRef;
  const audioElementRef = useRef(null);

  useEffect(() => {
    if (videoElementRef.current && stream) {
      videoElementRef.current.srcObject = stream;
    }
    if (audioElementRef.current && stream && audio) {
      audioElementRef.current.srcObject = stream;
    }
  }, [stream, audio, videoElementRef]);

  return (
    <Wrapper>
      <Video ref={videoElementRef} muted={audio === "off"} autoPlay></Video>
      {audio !== "off" && <audio ref={audioElementRef} autoPlay={true} />}
    </Wrapper>
  );
};

export default WebrtcVideo;
