export const sendChunkedImage = (dataUrl, uploadFunc) => {
  const chunkSize = 64000
  const size = dataUrl.length
  const chunksQuantity = Math.ceil(size/chunkSize)
  let chunksQueue = new Array(chunksQuantity).fill(null).map((_, index) => index + 1)
  if (!chunksQueue.length) {
    console.log("All parts uploaded");
    return;
  }
  const sendNext = () => {
    const chunkIndex = chunksQueue.shift()
    if (chunkIndex === undefined) {
      return
    }
    const begin = (chunkIndex - 1) * chunkSize;
    const chunk = dataUrl.slice(begin, begin + chunkSize);

    uploadFunc(chunk, chunkIndex, chunksQuantity)
    if (chunkIndex + 1 <= chunksQuantity) {
      sendNext()
    }
  }
  sendNext()
}
