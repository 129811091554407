import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import AuthGuard from "./components/wrappers/AuthGuard";

const AppRouter = () => (
  <Switch>
    <Route path="/login" exact render={() => <Login />} />
    <Route
      path="/dashboard"
      exact
      render={() => (
        <AuthGuard>
          <Dashboard />
        </AuthGuard>
      )}
    />
    <Redirect path="/" to="/login" />
  </Switch>
);

export default AppRouter;
