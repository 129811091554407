import React, { useContext } from "react";
// import { CircularProgress } from "@material-ui/core";
import styled, { css } from "styled-components";

import { StreamingContext } from "../../../store/context/StreamingContext";
import RemoteStream from "./RemoteStream";
import { dashboardShadow } from "../../../assets/styles/theme";

/* const LocalStream = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 17em;
  height: 10em;
`; */

const StreamWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  object-fit: cover;
`;

/* const Loader = styled(CircularProgress)`
  margin: auto;
`; */

const FlexWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  ${dashboardShadow}
`;

const TopWrapper = styled.div.attrs({ className: "stream-wrapper" })`
  width: 100%;
  height: calc(100% - 60px);
  ${({ isHidden }) =>
    isHidden
      ? css`
          display: none;
        `
      : css`
          display: inherit;
        `}
`;

const Stream = ({ isHidden }) => {
  const streamContext = useContext(StreamingContext);
  const remoteVideoRef = streamContext.state.remoteVideoRef;

  return (
    <TopWrapper isHidden={isHidden}>
      <FlexWrapper>
        <StreamWrapper>
          <RemoteStream videoRef={remoteVideoRef} />
        </StreamWrapper>
      </FlexWrapper>
    </TopWrapper>
  );
};

export default Stream;
