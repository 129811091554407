import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import { authReducer } from "./auth";
import { streamReducer } from "./stream";

const middlewares = [thunk];

const store = configureStore({
  reducer: {
    auth: authReducer,
    stream: streamReducer,
    canvas: "",
  },
  middleware: middlewares,
});

export default store;
