import { initWS } from "./ws";
import {
  closeRTCAndWSConnection,
  createRTCPeerConnectionAndMediaStream,
  onInitiateWebclientHangup,
  sendRtcAnnotationsChannelMessage,
} from "./rtc";

export const initSignalling = (handlers, connectionData) => {
  return {
    connectWs: () =>
      initWS(handlers, connectionData.wsHost, connectionData.wsToken),
    createRTCPeerConnectionAndMediaStream: () =>
      createRTCPeerConnectionAndMediaStream(
        connectionData.rtcConfiguration,
        handlers
      ),
    closeRTCAndWSConnection: closeRTCAndWSConnection, //or closeRTCAndWSConnection: () => closeRTCAndWSConnection(handlers),
    onInitiateWebclientHangup: () => onInitiateWebclientHangup(handlers),
    sendRtcAnnotationsChannelMessage,
  };
};
