import * as React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { TextField, withStyles } from "@material-ui/core";
import theme from "../../assets/styles/theme";

const CssTextField = withStyles({
  root: {
    width: "100%",
    "& label.Mui-focused": {
      color: theme.fontColorPrimary,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.fontColorPrimary,
    },
    "& .MuiOutlinedInput-root": {
      color: theme.fontColorPrimary,
      "& fieldset": {
        borderColor: theme.fontColorPrimary,
      },
      "&:hover fieldset": {
        borderColor: theme.fontColorPrimary,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.fontColorPrimary,
      },
    },
  },
})(TextField);

const Container = styled.div`
  width: 100%;
  font-family: inherit;
`;

const Input = ({ label, name, className, ...rest }) => {
  return (
    <Container className={className}>
      <CssTextField
        {...rest}
        id={name}
        name={name}
        label={label}
        variant="outlined"
        color="primary"
        size="medium"
        InputLabelProps={{ style: { color: theme.colorProductLight } }}
        InputProps={{
          style: {
            borderRadius: 0,
            borderWidth: "1px",
            borderColor: theme.fontColorPrimary,
          },
        }}
      />
    </Container>
  );
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default Input;
