import * as React from "react";
import styled from "styled-components";
import logoIcon from "../../assets/icons/logo-square.png";

const Image = styled.img`
  max-width: 15%;
  min-width: 100px;
  position: absolute;
  left: 4em;
  top: 3em;
`;

const Button = () => (
  <a target="_blank" href="https://viewpointsystem.com/en/">
    <Image src={logoIcon} />
  </a>
);

export default Button;
