/* eslint-disable import/prefer-default-export */
import React, { createContext, useState, useRef } from "react";
import Tools from "react-sketch2";

const CanvasContext = createContext(null);
export { CanvasContext };

const CanvasProvider = ({ children }) => {
  const [tool, setTool] = useState(Tools.Pencil);
  const [startUndo, setUndo] = useState(false);
  const sketchRef = useRef(null);
  const [isDrawingOpen, setIsDrawingOpen] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const [image, setImage] = useState(null);
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
  const [color, setColor] = useState("#eb9694");

  //TODO add logout to auth logout (when redirect)
  const logout = () => {
    setTool(Tools.Pencil);
    setIsDrawingOpen(false);
    setImage(null);
    setIsSideBarOpen(true);
    setColor("#eb9694");
  };

  const undo = () => {
    setUndo(true);
  };

  const value = {
    tool,
    setTool,
    startUndo,
    setUndo,
    undo,
    sketchRef,
    image,
    setImage,
    isDrawingOpen,
    setIsDrawingOpen,
    isSideBarOpen,
    setIsSideBarOpen,
    canvasSize,
    setCanvasSize,
    color,
    setColor,
    logout,
  };

  return (
    <CanvasContext.Provider value={value}>{children}</CanvasContext.Provider>
  );
};

export default CanvasProvider;
