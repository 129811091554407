import axios from "axios";
import authActions from "./actions";
import streamHelpers from "../../../services/stream/streamHelpers";
import * as stream from "stream";

//TODO: move URL to .env file
const KEYMASTER_HOST = "https://keymaster.viewpointsystem.com";

axios.defaults.baseURL = KEYMASTER_HOST;

const logIn = (expertNickname, pin) => (dispatch) => {
  const constructedWebclientPeerID = streamHelpers.constructWebClientPeerID(
    expertNickname
  );
  dispatch(authActions.loginRequest());
  axios
    .get(`/tokens/${pin}?webclient_peer_id=${constructedWebclientPeerID}`)
    .then(({ data }) => {
      // token.set(data.ws_token);
      const preparedData = {
        ...data,
        constructedWebclientPeerID,
        expertNickname,
        pin,
      };
      dispatch(authActions.loginSuccess(preparedData));
    })
    .catch(({ response: { status } }) => {
      let message = "Unexpected error! Please contact support team";
      if (status === 403) {
        message = "Incorrect pin. Please check entered pin and try again.";
      }
      dispatch(authActions.loginError(message));
    });
};

const logOut = () => (dispatch) => {
  console.log("logOut!");

  dispatch(authActions.logoutSuccess());
};

export default {
  logOut,
  logIn,
};
