import * as React from "react";
import StyledButton from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  customHoverFocus: {
    "&.MuiButton-root": {
      borderRadius: 0,
      margin: "0px",
      fontSize: "30px",
    },
  },
}));

const Button = ({ children, type, disabled, className }) => {
  const classes = useStyles();

  return (
    <StyledButton
      className={`${classes.customHoverFocus || ""} ${className || ""}`}
      type={type}
      disabled={disabled}
      variant="contained"
      color="primary"
      disableElevation
    >
      {children}
    </StyledButton>
  );
};

export default Button;
