import { log } from "../log";
import { handleWSMessage } from "./handleWSMessage";

let wsConnection = null;

const connect = (host, token) => {
  try {
    if (!host || !token) {
      log("Can't create wsConnection:", { host, token });
      return null;
    }
    const wsUrl = `${host}/v2/${token}`;
    const ws = new WebSocket(wsUrl);
    return ws;
  } catch (error) {
    throw new Error(`Ws connection failed, error: ${error}`);
  }
};

export const initWS = (externalHandlers, host, token) => {
  if (wsConnection) {
    return;
  }
  wsConnection = connect(host, token);

  const onWSServerOpen = (event) => {
    log("WebSocket connection has been opened!  event obj: ", event);
    externalHandlers.setIsWSOpen(true);
    // handlers.onConnect();
  };

  const onWSServerMessage = (event) => {
    handleWSMessage(event, externalHandlers);
  };

  const onWSServerClose = (event) => {
    log("WebSocket connection has been closed! ", event.reason);
    // handlers.onDisconnect();
    wsConnection = null;
    externalHandlers.setIsWSOpen(false);
  };

  const onWSServerError = (event) => {
    log("Error happened", event);
  };

  if (wsConnection) {
    wsConnection.addEventListener("open", onWSServerOpen);
    wsConnection.addEventListener("error", onWSServerError);
    wsConnection.addEventListener("message", onWSServerMessage);
    wsConnection.addEventListener("close", onWSServerClose);
  } else {
    log("error:  TOKEN / HOST/ TURN LIST not received.");
  }
};

export const getWsConnection = () => {
  if (wsConnection) {
    return wsConnection;
  } else {
    log("Can't get wsConnection:", wsConnection);
  }
};

export const sendMessage = (message) => {
  const wsInstance = getWsConnection();
  if (wsInstance) {
    if (wsInstance.readyState === WebSocket.OPEN) {
      wsInstance.send(message);
      return;
    }
  }

  log("Retry connecting to ws", wsConnection);
};

export const closeWs = () => {
  if (wsConnection && wsConnection.readyState < 2) {
    wsConnection.close();
  }
  wsConnection = null;
};
