const sendChannelLabel = "label-webclient";
const sendImageChannelLabel = "send-image";

const streamingProtocolVersion = 0;

export default {
  sendChannelLabel,
  sendImageChannelLabel,
  streamingProtocolVersion,
};
