import * as React from "react";
import { useContext } from "react";
import { StreamingContext } from "../../../store/context/StreamingContext";
import { useSelector } from "react-redux";
import { authSelectors } from "../../../store/redux/auth";

const ConnectSignalling = ({ children }) => {
  const streamContext = useContext(StreamingContext);
  const connectionData = useSelector(authSelectors.getConnectionData);
  const { wsHost } = connectionData;

  React.useEffect(() => {
    if (wsHost) {
      if (streamContext.state.streamLib && !streamContext.state.isWSOpen) {
        streamContext.state.streamLib.connectWs();
      }
      if (streamContext.state.isWSOpen && !streamContext.state.isRTCOpen) {
        streamContext.state.streamLib.createRTCPeerConnectionAndMediaStream();
      }
    }
  });

  return children;
};

export default ConnectSignalling;
