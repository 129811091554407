const getConnectionData = ({ auth }) => auth.connectionData;
const getIsRequestSuccess = ({ auth }) => auth.connectionData.isRequestSuccess;
const getError = ({ auth }) => auth.error;
const getLoading = ({ auth }) => auth.loading;

export default {
  getConnectionData,
  getIsRequestSuccess,
  getError,
  getLoading,
};
