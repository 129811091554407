export const resizeImg = (
  callback,
  { dataUrl, clientWidth, clientHeight, ratio }
) => {
  const bufferCanvas = document.createElement("canvas");
  bufferCanvas.width = clientWidth;
  bufferCanvas.height = clientHeight;

  const bufferCtx = bufferCanvas.getContext("2d");
  if (!bufferCtx) {
    console.error("no bufferCtx");
    return {};
  }

  let newWidth = bufferCanvas.width;
  let newHeight = newWidth / ratio;

  if (newHeight > bufferCanvas.height) {
    newHeight = bufferCanvas.height;
    newWidth = newHeight * ratio;
  }
  const xOffset =
    newWidth < bufferCanvas.width ? (bufferCanvas.width - newWidth) / 2 : 0;
  const yOffset =
    newHeight < bufferCanvas.height ? (bufferCanvas.height - newHeight) / 2 : 0;

  const img = new Image();

  img.addEventListener("load", () => {
    bufferCtx.drawImage(img, xOffset, yOffset, newWidth, newHeight);

    const newDataUrl = bufferCanvas.toDataURL();
    callback(newDataUrl);
  });

  img.src = dataUrl;
};
