import { log } from "../log";
import {
  onPeerConnected,
  onIncomingSDP,
  onIncomingICE,
  onIncomingHangupCommand,
  onIncomingAreYouAliveCommand,
  onIncommmingHangupOK,
} from "../rtc";

// todo find a way to refactor and split up the handleWSMessage function to make it more readable

export const handleWSMessage = (event, externalHandlers) => {
  const xirsysMessage = JSON.parse(event.data);
  log("received xirsysMessage: ", xirsysMessage, " event.data: ", event.data);
  // handle all xirsys messages types (peers, peer_connected, peer_removed, message)
  switch (xirsysMessage.m.o) {
    case "peers":
      log(`list of connected peers ${xirsysMessage.p}`);
      break;
    case "peer_connected":
      log(`peer just connected with name "${xirsysMessage.p}"`);
      onPeerConnected(xirsysMessage, externalHandlers);
      break;
    case "peer_removed":
      log(`peer just disconnected with name "${xirsysMessage.p}"`);
      break;
    case "message":
      log(`received message: "${xirsysMessage.p}"`);
      // handle incoming SDP
      const xirsysMessagePayload = JSON.parse(xirsysMessage.p);

      if (xirsysMessagePayload.sdp) {

        onIncomingSDP(xirsysMessagePayload.sdp, externalHandlers);
      } else if (xirsysMessagePayload.ice) {
        // handle incoming ICE
        log("xirsysMessage.p.ice case triggered");
        onIncomingICE(xirsysMessagePayload.ice);
      } else if (xirsysMessagePayload.streaming) {
        // detect message of type "command"
        switch (xirsysMessagePayload.streaming.command) {
          case "hangup":
            log(
              "command_received_hangup data: ",
              JSON.stringify(xirsysMessagePayload)
            );
            onIncomingHangupCommand(externalHandlers);
            break;
          case "areYouAlive":
            log(
              "command_received_areYouAlive data: ",
              JSON.stringify(xirsysMessagePayload)
            );
            onIncomingAreYouAliveCommand(xirsysMessage, externalHandlers);
            break;
        }
        switch (xirsysMessagePayload.streaming.status) {
          case "hangupOK":
            log(
              "status_received_hangupOK data: ",
              JSON.stringify(xirsysMessagePayload)
            );
            onIncommmingHangupOK(externalHandlers);
            break;
        }
      } else {
        log(
          "Error: Unknown incoming format of JSON object, message payload: " +
            xirsysMessagePayload
        );
      }
  }
};
