import { combineReducers } from "redux";
import { createReducer } from "@reduxjs/toolkit";
import streamActions from "./actions";

const initialStreamingDataState = {
  isRequestSuccess: false,
  remotePeerId: "",
};

const streamingData = createReducer(initialStreamingDataState, {
  [streamActions.setRemotePeerID]: (state, { payload }) => ({
    ...state,
    remotePeerId: payload,
  }),
  [streamActions.onResetStreamingData]: () => initialStreamingDataState,
});

const error = createReducer(null, {});

const loading = createReducer(false, {});

export default combineReducers({
  streamingData,
  error,
  loading,
});
