import { log } from "../log";
import { closeWs, getWsConnection, sendMessage as wsSendMessage } from "../ws";
import constants from "../constants";
import streamHelpers from "../streamHelpers";

const state = {
  localMediaStreamPromise: null,
  localMediaStream: null,
  rtcPeerConnection: null,
  // todo removed rtcSendChannel, check all further dependencies
  rtcSendChannel: null,
  rtcReceiveChannel: null,
  remotePeerId: null,
  rtcKeepAliveChannel: null,
  rtcAnnotationsChannel: null,
};

const initLocalMediaConstraint = {
  isVideoAllowed: false,
  isAudioAllowed: true,
};

//+++++++++++++++++++++++++++++++++
// handlers rtcSendChannel:

const onError = () => {
  log("Error rtcSendChannel");
  // TODO  set connection status to redux
};

//+++++++++++++++++++++++++++++++++
//helpers for handlers rtcPeerConnection:

export const closeRTCAndWSConnection = () => {
  if (state.rtcPeerConnection) {
    state.rtcPeerConnection.close();
    log("rtc_peer_connection_closed");
  } else {
    log(
      "rtc_peer_connection already closed or rtcPeerConnection:",
      state.rtcPeerConnection
    );
  }

  const wsConnection = getWsConnection();
  if (wsConnection) {
    closeWs();
    log("ws_connection_closed");
  } else {
    log("ws_connection already closed or ws_connection:", wsConnection);
  }

  state.rtcPeerConnection = null;
  state.rtcSendChannel = null;
  state.rtcReceiveChannel = null;
  state.remotePeerId = null;
  state.rtcKeepAliveChannel = null;
  state.rtcAnnotationsChannel = null;
};

const stopMediaStreamTracks = (externalHandlers) => {
  if (state.localMediaStream) {
    state.localMediaStream.getTracks().forEach((track) => {
      track.stop();
      log(`mediaStreamTrack ${track.id} has been stopped`);
    });
    externalHandlers.setLocalMediaStream = null;
    externalHandlers.setRemoteMediaStream = null;
    state.localMediaStream = null;
    state.localMediaStreamPromise = null;
  } else {
    log(
      `mediaStream can't be stopped, localMediaStream:`,
      state.localMediaStream
    );
  }
};

export const onIncomingHangupCommand = (externalHandlers) => {
  if (
    state.rtcPeerConnection &&
    state.rtcPeerConnection.connectionState === "connected"
  ) {
    const hangupOKStatus = streamHelpers.constructStreamingProtocolMessage(
      null,
      "hangupOK"
    );
    const xirsysHangupOKMessage = externalHandlers.constructWSMessage(
      hangupOKStatus,
      state.remotePeerId
    );
    wsSendMessage(xirsysHangupOKMessage);

    log("status_sent_hangupOK, hanging up.", null);
    closeRTCAndWSConnection();
    stopMediaStreamTracks(externalHandlers);

    externalHandlers.setIsRemoteHangup(true);
  }
};

//+++++++++++++++++++++++++++++++++
// handlers rtcPeerConnection:

const onLocalICECandidate = (externalHandlers) => (event) => {
  if (event.candidate == null) {
    return;
  }

  const ice = { ice: event.candidate };
  log("remotePeerId=", state.remotePeerId);
  //TODO check is externalHandlers.constructWSMessage exist
  const xirsysFormatedMessageWithICE = externalHandlers.constructWSMessage(
    ice,
    state.remotePeerId
  );
  wsSendMessage(xirsysFormatedMessageWithICE);
};

const onLocalICECandidateGatheringStateChange = (event) => {
  log("rtc_peer_connection.onicecandidate  event obj: ", event);
  let connection = event.target;
  switch (connection.iceGatheringState) {
    case "gathering":
      log(
        "ICECandidateGatheringStateChange: 'gathering' collection of candidates has begun"
      );
      break;
    case "complete":
      log(
        "ICECandidateGatheringStateChange: 'complete' collection of candidates is finished"
      );
      break;
    default:
      break;
  }
};

const onDataChannel = (externalHandlers) => (event) => {
  if (event.channel.label === "KeepAliveChannel") {
    state.rtcKeepAliveChannel = event.channel;
    log("KeepAliveChannel:", event.channel.label);
  }
  if (event.channel.label === "AnnotationsChannel") {
    state.rtcAnnotationsChannel = event.channel;
    log("AnnotationsChannel:", event.channel.label);
  } else {
    // todo remove rtcReceiveChannel (unused) , we use WS for sending the streaming protocol messages (hangup, alive)
    // check dependencies, make sure it doesn't break the things further
    log("onDataChannel() triggered Data channel EVENT obj: ", event);

    state.rtcReceiveChannel = event.channel;
    state.rtcReceiveChannel.onopen = (event) => {
      log(
        'receive_remote_data_channel rtcReceiveChannel.onopen in state "open", event:',
        event
      );
      state.rtcReceiveChannel.send(
        "Hi from webclient browser. The message triggered on rtcReceiveChannel.onopen "
      );
    };
    state.rtcReceiveChannel.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.command === "hangup") {
        log("receive_remote_data_channel_received_remote_command_hangup");
        onIncomingHangupCommand(externalHandlers);
      }

      log(
        "triggered rtcReceiveChannel.onmessage  rtcReceiveChannel.onmessage event.data MSG:",
        JSON.parse(event.data)
      );
    };
    state.rtcReceiveChannel.onerror = (event) => {
      log("receive_remote_data_channel_on_error", event);
    };
    state.rtcReceiveChannel.onclose = (event) => {
      log("receive_remote_data_channel_on_close", event);
    };
  }
};

const onRemoteTrack = (externalHandlers) => (event) => {
  log(
    "receive_remote_data_channel_on_track remote_track received, event obj: ",
    event
  );

  if (!externalHandlers.remoteMediaStream) {
    externalHandlers.setRemoteMediaStream(event.streams[0]);
  }
};

const onNegotiationNeeded = (event) => {
  log("on_negotiation_needed triggered event:", event);
};

const onConnectionStateChange = (externalHandlers) => (event) => {
  log(
    "connection_state_changed triggered, event obj: ",
    JSON.stringify(event) +
      ` connectionState::${state.rtcPeerConnection.connectionState}`
  );

  const payload = streamHelpers.constructStreamingProtocolMessage(null, {
    RTCPeerConnectionState: state.rtcPeerConnection.connectionState,
  });

  const xirsysFormattedMessage = externalHandlers.constructWSMessage(
    payload,
    state.remotePeerId
  );
  wsSendMessage(xirsysFormattedMessage);
};

const onRTCConnectionClose = (externalHandlers) => (event) => {
  log("rtc_peer_connection.onclose  ", `event: ${event}`);

  // // if not hangup state, it is reconnect state
  // if (connection_state.hangupRequired === false) {
  //   log(
  //     "RECONNECTION CASE onRTCConnectionClose connection_state.hangupRequired === false  ",
  //     ""
  //   );
  //   // todo re-enable reconnect
  //   // window.setTimeout(runConnectionSequenceRTCAndWS, 2000);
  // }
  externalHandlers.setIsRTCOpen("true");
};

//+++++++++++++++++++++++++++++++++
// Create rtc-connection and stream

const getLocalMediaStreamPromise = () => {
  const constraints = {
    video: initLocalMediaConstraint.isVideoAllowed,
    audio: initLocalMediaConstraint.isAudioAllowed,
  };
  // Add local media stream
  if (navigator.mediaDevices.getUserMedia) {
    return navigator.mediaDevices.getUserMedia(constraints);
  } else {
    // TODO  set connection status to redux
    // ("Browser doesn't support getUserMedia!");
  }
};

export const createRTCPeerConnectionAndMediaStream = (
  rtcConfiguration,
  externalHandlers
) => {
  if (!rtcConfiguration) {
    log(
      "Can't createRTCPeerConnectionAndMediaStream, rtcConfiguration:",
      rtcConfiguration
    );
    return;
  }
  if (state.rtcPeerConnection) {
    return;
  }
  log("Creating RTCPeerConnection");
  state.rtcPeerConnection = new RTCPeerConnection(rtcConfiguration);

  if (state.rtcSendChannel) {
    return;
  }
  state.rtcSendChannel = state.rtcPeerConnection.createDataChannel(
    constants.sendChannelLabel,
    null
  );
  // state.rtcImageSendChannel = state.rtcPeerConnection.createDataChannel(
  //   constants.sendImageChannelLabel,
  //   null
  // );

  state.rtcSendChannel.onopen = (event) => {
    log(
      `connection opened on channel: ${constants.sendChannelLabel}, event obj: ${event}`
    );
    state.rtcSendChannel.send(
      `Hi from webclient browser, via channel label: ${constants.sendChannelLabel}`
    );
    externalHandlers.setIsRTCOpen("true");
  };
  state.rtcSendChannel.onclose = () => {
    log("Close rtcSendChannel");
    externalHandlers.setIsRTCOpen(false);
  };
  state.rtcSendChannel.onerror = onError;

  state.rtcPeerConnection.onicecandidate = onLocalICECandidate(
    externalHandlers
  );
  state.rtcPeerConnection.onicegatheringstatechange = onLocalICECandidateGatheringStateChange;
  state.rtcPeerConnection.ondatachannel = onDataChannel(externalHandlers);
  state.rtcPeerConnection.ontrack = onRemoteTrack(externalHandlers);
  state.rtcPeerConnection.onnegotiationneeded = onNegotiationNeeded;
  state.rtcPeerConnection.onconnectionstatechange = onConnectionStateChange(
    externalHandlers
  );
  state.rtcPeerConnection.onclose = onRTCConnectionClose(externalHandlers);
};

//+++++++++++++++++++++++++++++++++
//other helpers

// Local description was set, send it to peer
const onLocalDescription = (localSDP, sdpType = "", constructWSMessage) => {
  log("Got local description: " + JSON.stringify(localSDP));
  state.rtcPeerConnection.setLocalDescription(localSDP).then(function () {
    // setStatus(`Sending SDP ${sdpType}`);
    const sdp = { sdp: state.rtcPeerConnection.localDescription };
    const payload = { sdp: sdp };

    const xirsysFormatedMessage = constructWSMessage(
      payload,
      state.remotePeerId
    );

    wsSendMessage(xirsysFormatedMessage);

    log(
      `sdp_outgoing type "${sdpType}" xirsysFormatedMessage(with sdp) `,
      xirsysFormatedMessage
    );
  });
};

const createLocalMediaStream = (externalHandlers) => {
  console.log("@#@# createLocalMediaStream");
  state.localMediaStreamPromise = getLocalMediaStreamPromise()
    .then((stream) => {
      // state.localMediaStream = stream;
      const prepareStream = stream;
      log("adding local media stream");
      // rtcPeerConnection.addStream(stream); //old version, new is:
      prepareStream.getTracks().forEach(function (track) {
        state.rtcPeerConnection.addTrack(track, stream);
      });
      externalHandlers.setLocalMediaStream(prepareStream);
      state.localMediaStream = prepareStream;
    })
    .catch((e) => log("Error: getLocalMediaStreamPromise, error:", e));
};

export const onIncomingSDP = (sdp, externalHandlers) => {
  // log("sdp_incoming", sdp);
  // setStatus("Got SDP offer");

  if (externalHandlers.isAudioAllowed) {
    createLocalMediaStream(externalHandlers);
  }

  state.rtcPeerConnection
    .setRemoteDescription(sdp)
    .then(() => {
      // setStatus("Remote SDP set");
      if (sdp.type !== "offer") {
        return;
      }
      //local_stream_promise
      if (state.localMediaStreamPromise) {
        state.localMediaStreamPromise
          .then((stream) => {
            // setStatus("Got local stream, creating answer ");
            log(
              "Got local stream, creating answer, local stream obj: ",
              stream
            );

            state.rtcPeerConnection
              .createAnswer()
              .then((sdp) =>
                onLocalDescription(
                  sdp,
                  "answer",
                  externalHandlers.constructWSMessage
                )
              )
              .catch((e) =>
                log("Error rtc onIncomingSDP (createAnswer), error:", e)
              );
          })
          .catch((e) =>
            log("Error rtc onIncomingSDP (localMediaStreamPromise), error:", e)
          );
      } else {
        // setStatus("Creating SDP answer");
        log("creating_sdp_answer");
        state.rtcPeerConnection
          .createAnswer()
          .then((sdp) =>
            onLocalDescription(
              sdp,
              "answer",
              externalHandlers.constructWSMessage
            )
          )
          .catch((e) => log("Error rtc onIncomingSDP (else),error:", e));
      }
    })
    .catch((e) => log("Error rtc setRemoteDescription, error:", e));
};

// ICE candidate received from peer, add it to the peer connection
export const onIncomingICE = (ice) => {
  let candidate = new RTCIceCandidate(ice);
  state.rtcPeerConnection
    .addIceCandidate(candidate)
    .then(() => {})
    .catch(
      (e) => log("Error addIceCandidate, error:", e)
      // setError
    );
};

export const onPeerConnected = (xirsysMessage, externalHandlers) => {
  if (
    //TODO: reserve name "smartunit"
    xirsysMessage.p.split("_").slice(-4)[0] === "smartunit"
  ) {
    state.remotePeerId = xirsysMessage.m.f.split("/").slice(-1)[0];
    //TODO dispatch like externalHandlers
    // dispatch(streamOperations.setRemotePeerID(remotePeerId));

    const payload = streamHelpers.constructStreamingProtocolMessage(
      "requestReconnect",
      null
    );

    const xirsysFormattedMessage = externalHandlers.constructWSMessage(
      payload,
      state.remotePeerId
    );

    wsSendMessage(xirsysFormattedMessage);
  }
};

// message of type COMMAND received from peer
export const onIncomingAreYouAliveCommand = (
  xirsysMessage,
  externalHandlers
) => {
  log("onIncomingAreYouAliveCommand triggered, xirsysMessage", xirsysMessage);
  state.remotePeerId = xirsysMessage.m.f.split("/").slice(-1)[0];
  //TODO dispatch like externalHandlers
  // dispatch(streamOperations.setRemotePeerID(remotePeerId));
  const payload = streamHelpers.constructStreamingProtocolMessage(
    null,
    "alive"
  );
  const responseMessage = externalHandlers.constructWSMessage(
    payload,
    state.remotePeerId
  );
  wsSendMessage(responseMessage);
};

export const onIncommmingHangupOK = (externalHandlers) => {
  // hangup confirmed from remote peer, close connections
  closeRTCAndWSConnection();
  stopMediaStreamTracks(externalHandlers);
  externalHandlers.setIsRemoteHangup(true);
};

export const onInitiateWebclientHangup = (externalHandlers) => {
  log("webclient hangup initiated");

  // const hangupCommand = {command: 'hangup'};
  const hangupCommand = streamHelpers.constructStreamingProtocolMessage(
    "hangup",
    null
  );

  const wsConnection = getWsConnection();
  if (wsConnection && wsConnection.readyState === 1) {
    const xirsysHangupMessage = externalHandlers.constructWSMessage(
      hangupCommand,
      state.remotePeerId
    );
    wsSendMessage(xirsysHangupMessage);
  } else {
    log("ws_conn:", wsConnection);
  }
  // don't close rtc/ws connections yet, wait for hangupOK confirmation from remote peer

  // // if no hangupOK received in 10 seconds, then hangup and close down rtc/ws
  // setTimeout(() => {
  //   const hangupOnTimeoutStatus = streamHelpers.constructStreamingProtocolMessage(
  //     null,
  //     "hangupOnTimeout"
  //   );
  //   const xirsysHangupOnTimeoutMessage =constructWSMessage(hangupOnTimeoutStatus,state.remotePeerId);
  //
  //   wsSendMessage(xirsysHangupOnTimeoutMessage);
  //   closeRTCAndWSConnection();
  //   stopMediaStreamTracks();
  //   log(
  //     "hangup_after_timeout  hung up on timeout on waiting for hangupOK",
  //     null
  //   );
  // }, 10000);
};

export const sendRtcAnnotationsChannelMessage = (message) => {
  if (
    !state.rtcAnnotationsChannel ||
    state.rtcAnnotationsChannel.readyState !== "open"
  ) {
    console.error(
      "trying to send data channel message before opening connection"
    );
    return;
  }
  state.rtcAnnotationsChannel.send(message);
};
