import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StreamingContext } from "../../../store/context/StreamingContext";
import { initSignalling } from "../../../services/stream/initSignalling";
import { authOperations, authSelectors } from "../../../store/redux/auth";
import streamHelpers from "../../../services/stream/streamHelpers";

const Signalling = ({ children }) => {
  const streamContext = useContext(StreamingContext);
  const connectionData = useSelector(authSelectors.getConnectionData);
  const { wsToken } = connectionData;
  const { isClientHangup, isRemoteHangup } = streamContext.state;

  const dispatch = useDispatch();

  useEffect(() => {
    const constructWSMessage = (message, remotePeerId) => {
      const prepareMessage = streamHelpers.constructXirsysFormattedMessage(
        connectionData.channel,
        connectionData.webclientPeerId,
        //TODO Maybe try to take remotePeerId from streamSelectors.getStreamingData
        remotePeerId,
        message
      );
      return JSON.stringify(prepareMessage);
    };

    if (isRemoteHangup) {
      dispatch(authOperations.logOut());
    }

    if (isClientHangup) {
      streamContext.state.streamLib.onInitiateWebclientHangup({
        constructWSMessage,
      });
      streamContext.setIsClientHangup(false);
    }

    if (streamContext.state.streamLib || !wsToken) {
      return;
    }
    const streaming = initSignalling(
      {
        constructWSMessage,
        setIsWSOpen: streamContext.setIsWSOpen,
        setIsRTCOpen: streamContext.setIsRTCOpen,
        setLocalMediaStream: streamContext.setLocalMediaStream,
        setRemoteMediaStream: streamContext.setRemoteMediaStream,
        localMediaStream: streamContext.localMediaStream,
        remoteMediaStream: streamContext.remoteMediaStream,
        setIsRemoteHangup: streamContext.setIsRemoteHangup,
        isAudioAllowed: streamContext.state.isAudioAllowed,
      },
      connectionData
    );
    streamContext.setStreamLib(streaming);
  }, [streamContext, wsToken, isClientHangup, isRemoteHangup]);

  return children;
};

export default Signalling;
