import React, { createContext, useState, useRef } from "react";

const StreamingContext = createContext(null);

export { StreamingContext };

export default ({ children }) => {
  const [localMediaStream, setLocalMediaStream] = useState(null);
  const [remoteMediaStream, setRemoteMediaStream] = useState(null);
  const [isVideoAllowed, setIsVideoAllowed] = useState(false);
  const [isAudioAllowed, setIsAudioAllowed] = useState(true);

  const [streamLib, setStreamLib] = useState(null);
  const [isWSOpen, setIsWSOpen] = useState(false);
  const [isRTCOpen, setIsRTCOpen] = useState(false);

  const [isRemoteHangup, setIsRemoteHangup] = useState(false);
  const [isClientHangup, setIsClientHangup] = useState(false);

  const remoteVideoRef = useRef(null);
  const localVideoRef = useRef(null);

  const value = {
    localMediaStream,
    remoteMediaStream,

    state: {
      streamLib,
      isWSOpen,
      isRTCOpen,
      isRemoteHangup,
      isClientHangup,
      remoteVideoRef,
      localVideoRef,
      isVideoAllowed,
      isAudioAllowed,
    },

    setStreamLib,
    setIsWSOpen,
    setIsRTCOpen,
    setLocalMediaStream,
    setRemoteMediaStream,
    setIsRemoteHangup,
    setIsClientHangup,
    setIsVideoAllowed,
    setIsAudioAllowed,
  };

  return (
    <StreamingContext.Provider value={value}>
      {children}
    </StreamingContext.Provider>
  );
};
