import React, { useContext } from "react";
import styled from "styled-components";
import { StreamingContext } from "../../../store/context/StreamingContext";
import { CanvasContext } from "../../../store/context/CanvasContext";

import ButtonRound from "../../elements/ButtonRound";
import ToggleButtonRound from "../../elements/ToggleButtonRound";
import DrawingTools from "./DrawingTools";
import { sendChunkedImage } from "../../../services/sendChunkedImage";

import screenshotIcon from "../../../assets/icons/screenshot.png";
import annotateIcon from "../../../assets/icons/annotate.png";
import uploadIcon from "../../../assets/icons/upload.png";
import downloadIcon from "../../../assets/icons/download.png";
import sendIcon from "../../../assets/icons/send.png";

const screenshotFileName = "screenshot.png";

const Wrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  align-self: flex-end;
  width: 100%;
  height: 60px;
`;

const ContentWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  max-height: 4em;
  background: #3c3e4f;
  width: 100%;
`;

const HiddenInput = styled.input`
  display: none;
`;

const Navbar = () => {
  const streamContext = useContext(StreamingContext);
  const canvasContext = useContext(CanvasContext);
  const {
    isDrawingOpen,
    setIsDrawingOpen,
    setImage,
    image,
    sketchRef,
  } = canvasContext;
  const fileRef = React.useRef(null);

  // TODO extract
  const createScreenshot = React.useCallback(() => {
    if (streamContext.state.remoteVideoRef.current) {
      const img = streamContext.state.remoteVideoRef.current;

      if (img) {
        const bufferCanvas = document.createElement("canvas");

        let videoWidth, videoHeight;
        if (img instanceof HTMLVideoElement) {
          [videoWidth, videoHeight] = [img.videoWidth, img.videoHeight];
        } else {
          console.error(
            "can't figure out how to use this image (not a video/image/canvas html element)"
          );
          return {};
        }

        if (videoWidth === 0 || videoHeight === 0) {
          console.error(
            "the image seems to be invalid (width or height are 0)"
          );
          return {};
        }

        bufferCanvas.width = videoWidth;
        bufferCanvas.height = videoHeight;
        const bufferCtx = bufferCanvas.getContext("2d");
        if (!bufferCtx) {
          console.error("no bufferCtx");
          return {};
        }
        bufferCtx.drawImage(img, 0, 0, videoWidth, videoHeight);

        const dataUrl = bufferCanvas.toDataURL();
        console.log({ img, dataUrl });

        return {
          dataUrl,
          imageWidth: videoWidth,
          imageHeight: videoHeight,
          clientWidth: img.clientWidth,
          clientHeight: img.clientHeight,
          ratio: videoWidth / videoHeight,
        };
      }
      return {};
    }
    return {};
  }, [streamContext]);

  const onDrawingToggle = React.useCallback(
    (val) => {
      if (val && !image) {
        const { dataUrl } = createScreenshot();
        setIsDrawingOpen(val);

        if (dataUrl) {
          setImage(dataUrl);
        }
      } else {
        if (!val) {
          setImage(null);
        }
        setIsDrawingOpen(val);
      }
    },
    [setIsDrawingOpen, image, streamContext, sketchRef]
  );

  const takeScreenshot = React.useCallback(() => {
    const { dataUrl } = createScreenshot();

    if (!dataUrl) {
      console.log("No data url");
      return;
    }

    const element = document.createElement("a");
    element.href = dataUrl;
    element.download = screenshotFileName;
    element.click();
  }, []);

  const uploadImage = React.useCallback(
    (event) => {
      const file = event.target.files[0];
      if (!file) {
        return;
      }
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          setIsDrawingOpen(true);
          if (!reader.result) {
            console.log("No image selected");
            return;
          }
          setImage(reader.result.toString());
        },
        false
      );
      reader.readAsDataURL(file);
    },
    [sketchRef]
  );

  const downloadImage = React.useCallback(() => {
    if (!sketchRef.current) {
      console.error("Canvas is not opened");
      return;
    }
    const dataUrl = sketchRef.current.toDataURL();
    const element = document.createElement("a");
    element.href = dataUrl;
    element.download = screenshotFileName;
    element.click();
  }, []);

  const unCheckAnnotation = React.useCallback(() => {
    return !isDrawingOpen;
  }, [isDrawingOpen]);

  const checkAnnotation = React.useCallback(() => {
    return isDrawingOpen;
  }, [isDrawingOpen]);

  const sendImage = React.useCallback(() => {
    if (!sketchRef.current) {
      console.error("Canvas is not opened");
      return;
    }
    const currentCanvas = sketchRef.current;
    const dataUrl = currentCanvas.toDataURL("image/png");
    const id = Math.random().toString(36).substr(2, 5);
    sendChunkedImage(dataUrl, (chunk, chunkIndex, count) => {
      const rtcMessage = {
        event: "send-image",
        data: {
          id,
          chunk: {
            totalCount: count,
            index: chunkIndex,
            data: chunk,
          },
        },
      };
      console.log({ rtcMessage });
      streamContext.state.streamLib.sendRtcAnnotationsChannelMessage(
        JSON.stringify(rtcMessage)
      );
    });
  }, [streamContext, sketchRef]);

  return (
    <Wrapper>
      <ContentWrapper>
        {/* <span> */}
        <div>
          <ButtonRound
            onClick={takeScreenshot}
            iconSrc={screenshotIcon}
            tooltipTitle="screenshot"
          />
          <ToggleButtonRound
            iconSrc={annotateIcon}
            onToggle={onDrawingToggle}
            unCheck={unCheckAnnotation}
            check={checkAnnotation}
            tooltipTitle="open editor"
          />
          <HiddenInput
            id="image-upload"
            type="file"
            onChange={uploadImage}
            accept="image/*"
            ref={fileRef}
          />
          <ButtonRound
            onClick={() => fileRef.current && fileRef.current.click()}
            iconSrc={uploadIcon}
            tooltipTitle="upload"
          />
          {isDrawingOpen && (
            <ButtonRound
              onClick={downloadImage}
              iconSrc={downloadIcon}
              tooltipTitle="download image"
            />
          )}
          {isDrawingOpen && (
            <ButtonRound
              onClick={sendImage}
              iconSrc={sendIcon}
              tooltipTitle="send image"
            />
          )}
        </div>
        {isDrawingOpen && <DrawingTools />}

        {/* </span> */}
      </ContentWrapper>
    </Wrapper>
  );
};

export default Navbar;
