import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider as ThemeProviderMui } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import { CssBaseline } from "@material-ui/core";
import StreamingProvider from "./store/context/StreamingContext";
import CanvasProvider from "./store/context/CanvasContext";
import theme, { muiTheme } from "./assets/styles/theme";

import AppRouter from "./AppRouter";
import Signalling from "./components/dashboard/stream/Signalling";
// todo Update Readme on how to run new  bly-fe   react app
function App() {
  return (
    <ThemeProviderMui theme={muiTheme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <StreamingProvider>
            <CanvasProvider>
              <Signalling>
                {/*TODO check maybe move component only to dashboard*/}
                <AppRouter />
              </Signalling>
            </CanvasProvider>
          </StreamingProvider>
        </Router>
      </ThemeProvider>
    </ThemeProviderMui>
  );
}

export default App;
