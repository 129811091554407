import { createAction } from "@reduxjs/toolkit";

const setRemotePeerID = createAction("stream/setRemotePeerID");

const onResetStreamingData = createAction("stream/onResetStreamingData");

export default {
  setRemotePeerID,
  onResetStreamingData,
};
