import { v4 } from "uuid";
import constants from "./constants";

const webclientSessionUuid = v4();

function generateUnixTimestamp() {
  return Date.now();
}

function constructWebClientPeerID(expertNickname) {
  return (
    "webclient" +
    "_" +
    expertNickname +
    "_" +
    webclientSessionUuid +
    "_" +
    generateUnixTimestamp()
  );
}

function constructXirsysFormattedMessage(
  channel,
  fromUsernameExpertPeerId,
  toPeerId,
  payload
) {
  return {
    t: "u",
    m: {
      f: `${channel}/${fromUsernameExpertPeerId}`,
      t: toPeerId,
      o: "message",
    },
    p: payload,
  };
}

function constructStreamingProtocolMessage(command, status) {
  const protocolMessage = {
    streaming: { protocol_version: constants.streamingProtocolVersion },
  };

  if (command) {
    protocolMessage.streaming.command = command;
  }

  if (status) {
    protocolMessage.streaming.status = status;
  }

  return protocolMessage;
}

export default {
  constructWebClientPeerID,
  constructXirsysFormattedMessage,
  constructStreamingProtocolMessage,
};
