import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router";
import { useSelector } from "react-redux";
import { authSelectors } from "../../store/redux/auth";
import { StreamingContext } from "../../store/context/StreamingContext";

const AuthGuard = ({ children }) => {
  const isRequestSuccess = useSelector(authSelectors.getIsRequestSuccess);
  const streamContext = useContext(StreamingContext);

  useEffect(() => {
    if (!isRequestSuccess && streamContext.state.isRemoteHangup) {
      streamContext.setIsRemoteHangup(false);
      streamContext.setStreamLib(null);
      streamContext.setLocalMediaStream(null);
      streamContext.setRemoteMediaStream(null);
    }
  }, [isRequestSuccess]);

  if (!isRequestSuccess) {
    return <Redirect to="login" />;
  }

  return children;
};

export default AuthGuard;
