import React, { useContext } from "react";
import styled from "styled-components";

import { StreamingContext } from "../../../store/context/StreamingContext";
import WebrtcVideo from "./WebrtcVideo";
import { dashboardShadow } from "../../../assets/styles/theme";

const RemoteStreamWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const StreamWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  object-fit: cover;
`;

const MainWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  ${dashboardShadow}
`;

const RemoteStream = ({ className, videoRef, audio }) => {
  const { remoteMediaStream } = useContext(StreamingContext);
  return (
    <MainWrapper>
      <StreamWrapper className={className}>
        <RemoteStreamWrapper>
          {remoteMediaStream && (
            <WebrtcVideo
              videoRef={videoRef}
              stream={remoteMediaStream}
              audio={audio}
            ></WebrtcVideo>
          )}
        </RemoteStreamWrapper>
      </StreamWrapper>
    </MainWrapper>
  );
};

export default RemoteStream;
