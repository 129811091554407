import * as React from "react";
import PropTypes from "prop-types";
import ButtonRound from "./ButtonRound";

const ToggleButtonRound = ({
  iconSrc,
  switchIconSrc,
  onToggle,
  unCheck,
  check,
  onlyCheck,
  checked,
  tooltipTitle,
  children,
  ...rest
}) => {
  const [isSelected, setIsSelected] = React.useState(!!checked);
  const [currentIconSrc, setCurrentIconSrc] = React.useState(iconSrc);

  const handleOnClick = React.useCallback(() => {
    if (onlyCheck) {
      onToggle(true);
      setIsSelected(true);
      return;
    }
    onToggle(!isSelected);
    setIsSelected(!isSelected);
  }, [setIsSelected, isSelected]);

  React.useEffect(() => {
    if (switchIconSrc) {
      if (isSelected) {
        setCurrentIconSrc(switchIconSrc);
      }
      if (!isSelected) {
        setCurrentIconSrc(iconSrc);
      }
    }
  }, [isSelected, switchIconSrc, iconSrc]);

  React.useEffect(() => {
    if (unCheck && unCheck()) {
      setIsSelected(false);
    }
    if (check && check()) {
      setIsSelected(true);
    }
  }, [isSelected, unCheck, check]);

  return (
    <ButtonRound
      onClick={handleOnClick}
      iconSrc={currentIconSrc || iconSrc}
      selected={!switchIconSrc && isSelected}
      tooltipTitle={tooltipTitle}
      {...rest}
    >
      {children}
    </ButtonRound>
  );
};

ToggleButtonRound.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  switchIconSrc: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
  unCheck: PropTypes.func,
  check: PropTypes.func,
  onlyCheck: PropTypes.bool,
  checked: PropTypes.bool,
  iconSize: PropTypes.string,
  tooltipTitle: PropTypes.string,
};

export default ToggleButtonRound;
