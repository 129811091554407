import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { SketchField } from "react-sketch2";
import { useMeasure } from "react-use";

import { CanvasContext } from "../../../store/context/CanvasContext";
import { dashboardShadow } from "../../../assets/styles/theme";
import ButtonRound from "../../elements/ButtonRound";
import { resizeImg } from "../../../services/resizeImg";

import closeIcon from "../../../assets/icons/close.png";
import { CustomTools } from "./DrawingTools";
import { fabric } from "fabric";
const StyledCanvas = styled.div.attrs({ className: "canvas" })`
  width: 100%;
  height: calc(100% - 60px);
  overflow: hidden;
  position: relative;
  ${({ isHidden }) =>
    isHidden
      ? css`
          display: none;
        `
      : css`
          display: inherit;
        `}
  .upper-canvas {
    ${dashboardShadow}
  }
`;

/* const MaximiseCanvasButton = styled(ButtonRound)`
  top: 0;
  right: 45px;
  height: 60px;
  position: absolute !important;
  z-index: 1;
`; */

const ExitCanvasButton = styled(ButtonRound)`
  top: 0;
  right: 0;
  height: 60px;
  position: absolute !important;
  z-index: 1;
`;

export const sleep = (ms) =>
  new Promise((res) => {
    setTimeout(res, ms);
  });

export const waitFor = async (predicate, ms = 10, timeout = 5000) => {
  let timePassed = 0;
  while (!predicate()) {
    if (timePassed >= timeout) {
      Promise.reject();
    }
    await sleep(ms);
    timePassed += ms;
  }
};

const Canvas = ({ className, isHidden }) => {

  const [isDrawingArrow, setIsDrawingArrow] = React.useState(false);
  const [startX, setStartX] = React.useState(0);
  const [startY, setStartY] = React.useState(0);
  const [customDrawings, setCustomDrawings] = React.useState([]);
  const [undoStack, setUndoStack] = React.useState([]);


  const canvasContext = useContext(CanvasContext);
  const {
    startUndo,
    setUndo,
    tool,
    sketchRef,
    setImage,
    image,
    canvasSize,
    setCanvasSize,
    setIsDrawingOpen,
    isSideBarOpen,
    setIsSideBarOpen,
    color,
  } = canvasContext;

  const [CHANGE_ME_Ref, { width, height }] = useMeasure();
  const [oldSize, setOldSize] = React.useState({});

  const TEMP_ARROW_ID = 'dynamicArrow';

  React.useEffect(() => {
    if (typeof width === "number" && typeof height === "number") {
      setCanvasSize({ width, height });
    }
    // TODO: not working on resize of window
  }, [width, height]);

  React.useEffect(() => {
    if (sketchRef.current) {
      const canvas = sketchRef.current._fc;
      if (startUndo) {
        if (undoStack.length > 0) {
          const lastAction = undoStack.pop();
          if (lastAction.type === "defaultAction") {
            if (sketchRef.current.canUndo()) {
              sketchRef.current.undo();
            }

          } else {
            lastAction.objects.forEach(obj => {
              canvas.remove(obj);
            });
          }
          setUndo(false);
          setUndoStack([...undoStack])
        }
      }
    }
  }, [startUndo, sketchRef]);

  React.useEffect(() => {
    if (image && sketchRef.current) {
      // todo review if waitfor function can be refactored (removed completely) FIX HACK
      waitFor(() => sketchRef.current.props.height).then(() => {
        const imageEl = new Image();
        if (!imageEl) {
          console.log("Image missing");
          return;
        }
        imageEl.onload = () => {
          resizeImg(
            (img) => {
              sketchRef.current.setBackgroundFromDataUrl(img);
            },
            {
              dataUrl: image,
              clientHeight: sketchRef.current.props.height,
              clientWidth: sketchRef.current.props.width,
              ratio: imageEl.width / imageEl.height,
            }
          );
          sketchRef.current._backgroundColor("#fff");
          setImage(null);
        };
        imageEl.src = image;
        initializeText();
      });
    }
  }, [image, sketchRef]);

  const exitCanvas = React.useCallback(() => {
    sketchRef.current.clear();
    setIsDrawingOpen(false);
    setImage(null);
  }, []);


  const handleMouseDown = (event) => {
    if (sketchRef.current) {
      const canvas = sketchRef.current._fc;
      const e = event.nativeEvent;

      const canvasRect = e.target.getBoundingClientRect();
      const offsetX = e.clientX - canvasRect.left;
      const offsetY = e.clientY - canvasRect.top;

      if (tool === CustomTools.CustomArrow) {
        setIsDrawingArrow(true);
        setStartX(offsetX);
        setStartY(offsetY);
      } else if (tool === CustomTools.Text) {
        const text = new fabric.IText('Note', {
          left: offsetX,
          top: offsetY,
          fill: color,
          fontSize: 40,
          fontFamily: 'Campton, sans-serif'
        });
        canvas.add(text);
        canvas.setActiveObject(text);
        text.enterEditing();
        text.selectAll();
        canvas.renderAll();
        setUndoStack([...undoStack, { type: 'customAction', objects: [text] }])
      } else {
        setIsDrawingArrow(false);
        return;
      }
    }
  };


  const handleMouseMove = (event) => {
    if (sketchRef.current) {
      const canvas = sketchRef.current._fc;
      const e = event.nativeEvent;

      const canvasRect = e.target.getBoundingClientRect();
      const offsetX = e.clientX - canvasRect.left;
      const offsetY = e.clientY - canvasRect.top;

      if (isDrawingArrow && tool === CustomTools.CustomArrow) {
        const renderedDynamicArrows = canvas.getObjects().filter(obj => obj.customType === TEMP_ARROW_ID);
        renderedDynamicArrows.forEach(obj => canvas.remove(obj));
        const endX = offsetX;
        const endY = offsetY;
        const points = [startX, startY, endX, endY];
        const arrowConfig = {
          strokeWidth: 3,
          fill: color,
          stroke: color,
          customType: TEMP_ARROW_ID
        }
        const line = new fabric.Line(points, arrowConfig);
        const headLength = 20;
        const angle = Math.atan2(endY - startY, endX - startX);
        const makeArrowHead = (isLeft) => new fabric.Line(
          [endX,
            endY,
            endX - headLength * Math.cos(isLeft ? angle - Math.PI / 6 : angle + Math.PI / 6),
            endY - headLength * Math.sin(isLeft ? angle - Math.PI / 6 : angle + Math.PI / 6)],
          arrowConfig
        );
        canvas.add(line, makeArrowHead(true), makeArrowHead(false));
      }
    }
  };

  const handleMouseUp = (event) => {
    if (sketchRef.current) {
      if (isDrawingArrow && tool === CustomTools.CustomArrow) {
        const canvas = sketchRef.current._fc;
        const renderedDynamicArrows = canvas.getObjects().filter(obj => obj.customType === TEMP_ARROW_ID);
        renderedDynamicArrows.forEach(obj => {
          obj.customType = undefined;
        });
        setUndoStack([...undoStack, { type: 'customAction', objects: renderedDynamicArrows }])
        setCustomDrawings([...customDrawings, ...renderedDynamicArrows]);
        setIsDrawingArrow(false);
      }
    }
  };

  const initializeText = () => {
    if (sketchRef.current) {
      const text = new fabric.IText('Test', {
        left: 0,
        top: 0,
        fontFamily: 'Campton, sans-serif'
      });
      sketchRef.current._fc.add(text).remove(text);
    }
  }

  const preventBubblingIfCustomTool = (tool, e) => {
    const isCustomTool = tool === CustomTools.CustomArrow || tool === CustomTools.Text;
    if (isCustomTool) e.stopPropagation();
    return isCustomTool;
  }

  /*  const maximiseCanvas = React.useCallback(() => {
    if (isSideBarOpen) {
      setOldSize({ width, height });
    }
    if (!isSideBarOpen && oldSize.width && oldSize.height) {
      setCanvasSize({ width: oldSize.width, height: oldSize.height });
    }
    setIsSideBarOpen(!isSideBarOpen);
  }, [isSideBarOpen, width, height]); */

  return (
    <StyledCanvas
      ref={CHANGE_ME_Ref}
      className={className}
      isHidden={isHidden}
      onMouseDownCapture={(e) => preventBubblingIfCustomTool(tool, e) && handleMouseDown(e)}
      onMouseUpCapture={(e) => preventBubblingIfCustomTool(tool, e) ? handleMouseUp(e) : setUndoStack([...undoStack, { type: 'defaultAction' }])}
      onMouseMoveCapture={(e) => preventBubblingIfCustomTool(tool, e) && handleMouseMove(e)}
    >
      {/*/!* <MaximiseCanvasButton iconSrc="/expand.png" onClick={maximiseCanvas} /> *!/*/}
      {!isHidden && (
        <ExitCanvasButton
          iconSrc={closeIcon}
          onClick={exitCanvas}
          tooltipTitle="close"
        />
      )}
      <SketchField
        width={canvasSize.width}
        widthCorrection={0}
        height={canvasSize.height}
        tool={tool}
        lineColor={color}
        lineWidth={3}
        ref={sketchRef}
        backgroundColor="#fff"
      />
    </StyledCanvas>
  );
};

export default Canvas;
