import React, { useEffect, useContext } from "react";
import styled from "styled-components";

import { StreamingContext } from "../store/context/StreamingContext";
import { CanvasContext } from "../store/context/CanvasContext";
import ConnectSignalling from "../components/dashboard/stream/ConnectSignalling";
import Navbar from "../components/dashboard/Navbar";
import Stream from "../components/dashboard/stream/Stream";
import Canvas from "../components/dashboard/canvas/Canvas";
import BottomBar from "../components/dashboard/canvas/BottomBar";

const Wrapper = styled.div.attrs({ className: "dashboard" })`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const StreamWrapper = styled.div.attrs({ className: "Stream-wrapper" })`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
`;

const CanvasWrapper = styled.div.attrs({ className: "Canvas-wrapper" })`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - 81px);
`;

const Dashboard = () => {
  const streamContext = useContext(StreamingContext);
  const canvasContext = useContext(CanvasContext);
  const { isRemoteHangup } = streamContext.state;

  const { isDrawingOpen } = canvasContext;

  const sendHangupSignal = () => {
    streamContext.setIsClientHangup(true);
  }

  useEffect(() => {
    // listen to window/tab close event
    window.addEventListener("beforeunload", sendHangupSignal);

    // on component unMount cleanup event listeners
    return () => {
      window.removeEventListener("beforeunload", sendHangupSignal);
    }
  }, []);

  return (
    <ConnectSignalling>
      <Wrapper>
        <Navbar />
        <CanvasWrapper>
          <StreamWrapper>
            <Canvas isHidden={!isDrawingOpen} />
            <Stream isHidden={isRemoteHangup || isDrawingOpen} />
            {isRemoteHangup && <p>Connection was closed remotely</p>}
            <BottomBar />
          </StreamWrapper>
        </CanvasWrapper>
      </Wrapper>
    </ConnectSignalling>
  );
};

export default Dashboard;
